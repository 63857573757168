<template>
  <b-modal
    id="discountSelectorModal"
    ref="discountSelectorModal"
    title="Edit discount"
    ok-only
    ok-title="Save discount"

    @ok="saveData"
  >
    <div v-if="discountData">
      <!-- form -->

      <status-alert
        :is-success="isSuccess"
        :error-data="errorData"
        :success-msg="successMsg"
      />
      <b-row>
        <b-col
          md="12"
          lg="12"
        >
          <validation-observer

            ref="formClient"
          > <b-form
            class="p-2"
          >
            <b-alert

              show

              fade
              class="mb-3"
              variant="warning"
            >
              <div class="alert-body ">
                The discount will be applied to the subtotal. This does not include discounts added to specific items.
              </div>
            </b-alert>
            <validation-provider
              #default="{errors}"
              :name="$t('Discount')"
              rules="required"
            >
              <!-- Name -->
              <b-col cols="12">

                <b-form-group

                  label-for="client-name"
                >

                  <b-row>

                    <b-col class="d-flex justify-content-between justify-content align-items-center ">

                      <b-input-group
                        size="lg"
                      >

                        <cleave
                          id="price"
                          v-model="discountData.discount"
                          size="lg"
                          class="form-control-lg form-control"
                          :raw="true"
                          :options="discountClaveOptions"
                        />
                        <b-button-group size="lg">
                          <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"

                            :variant="discountData.type==1?'dark':'outline-dark'"
                            @click="discountData.type=1"
                          >
                            %
                          </b-button>
                          <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            :variant="discountData.type==2?'dark':'outline-dark'"
                            @click="discountData.type=2"
                          >
                            {{ currency }}
                          </b-button>

                        </b-button-group>
                      </b-input-group>
                    </b-col>
                  </b-row>
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>

              </b-col>
            </validation-provider>
            <!--/ Name -->

          </b-form>
          </validation-observer>
        </b-col>

      </b-row>

    </div>
  </b-modal>
</template>

<script>
import {
  BForm, BFormGroup, BRow, BCol, BFormInvalidFeedback, BModal, BButton, BInputGroup, BButtonGroup, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BButtonGroup,
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BAlert,
    BModal,
    BButton,
    Cleave,
  },
  directives: {
    Ripple,
  },
  props: {
    currency: {
      type: String,
      default: '',
    },
  },
  data() {
    return {

      discountClaveOptions: null,
      required,
      alphaNum,
      email,
      isSuccess: false,
      successMsg: 'Discount updated',
      errorData: null,
      blankDiscountData: {
        discount: 0,
        type: 1,
      },
      discountData: null,
    }
  },
  watch: {
    discountData: {
      deep: true,
      handler(val) {
        if (val.type === 1) {
          this.discountClaveOptions.prefix = '% '
        } else {
          this.discountClaveOptions.prefix = `${this.currency} `
        }
      },
    },
  },
  created() {
    this.resetForm()
    this.discountClaveOptions = this.$staticParams.currencyClaveOptions()
  },
  methods: {

    showEditor(discountData) {
      if (discountData == null) {
        this.resetForm()
      } else {
        this.discountData = JSON.parse(JSON.stringify(discountData))
      }
      this.$refs.discountSelectorModal.show()
    },
    closeEditor() {
      this.$refs.discountSelectorModal.hide()
    },

    cancel() {
      this.$emit('closed')
    },
    deleteData() {

    },
    saveData() {
      this.$refs.formClient.validate().then(success => {
        if (success) {
          this.$emit('discountSaved', this.discountData)
        }
      })
    },
    resetForm() {
      this.discountData = JSON.parse(JSON.stringify(this.blankDiscountData))
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
