<template>

  <div v-if="invoiceData.design">
    <!-- form -->

    <status-alert
      :is-success="isSuccess"
      :error-data="errorData"
      :success-msg="successMsg"
    />
    <b-row>
      <b-col
        md="12"
        lg="12"
      >

        <validation-observer

          ref="refFormRecurringData"
        > <b-form
          class="p-2"
        >
          <b-col
            cols="12"
            class="p-0"
          >
            <b-form-checkbox
              v-model="recurringInvoiceData.enable_recurring"
              checked="true"
              name="check-button"
              switch
              inline
              :value="1"
              :unchecked-value="0"
            >
              <h4>
                Enable Recurring Mode
              </h4>
            </b-form-checkbox>
          </b-col>
          <hr>
          <div v-if="recurringInvoiceData.enable_recurring">

            <b-col
              md="12"
              lg="12"
            >

              <validation-provider
                #default="{errors}"
                :name="$t('Recurring Period')"
                rules="required"
              >
                <!-- Name -->
                <b-col
                  cols="12"
                >

                  <b-form-group
                    label="Recurring Period"
                    label-for="recurring-period"
                    label-cols-md="2"
                  >
                    <v-select
                      v-model="recurringInvoiceData.recurringPeriod"

                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="recurringPeriodOptions"
                      :reduce="val => val.value"
                      autocomplete="chrome-off"
                      :clearable="false"
                      input-id="recurring-period"
                      :state="errors.length > 0 ? false:null"
                    />
                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                </b-col>
              </validation-provider>
              <validation-provider
                #default="{errors}"
                :name="$t('Start On')"
                rules="required"
              >
                <!-- Name -->
                <b-col
                  cols="12"
                  class="p-0"
                >

                  <b-form-group
                    label="Start On"
                    label-for="recurring-start-date"
                    label-cols-md="2"
                  >
                    <b-form-datepicker
                      id="recurring-start-date"
                      v-model="recurringInvoiceData.start_date"

                      local="en"
                      today-variant="danger"
                      :state="errors.length > 0 ? false:null"
                    />

                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                </b-col>
              </validation-provider>
              <validation-provider
                #default="{errors}"
                name="$t('Ends On (Optional)')"
              >
                <!-- Name -->
                <b-col
                  cols="12"
                  class="p-0"
                >

                  <b-form-group
                    :label="$t('Ends On (Optional)')"
                    label-for="recurring-end-date"
                    label-cols-md="2"
                  >
                    <b-form-datepicker
                      id="recurring-end-date"
                      v-model="recurringInvoiceData.end_date"

                      local="en"
                      today-variant="danger"
                      :state="errors.length > 0 ? false:null"
                    />

                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                </b-col>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              class="p-0"
            >
              <b-form-checkbox
                v-model="recurringInvoiceData.send_mail_settings.enabled"
                checked="true"
                name="check-button"
                switch
                inline
                :value="1"
                :unchecked-value="0"
              >
                <h4>
                  Send invoice as email
                </h4>
              </b-form-checkbox>
            </b-col>
            <hr>
            <b-col
              v-if="recurringInvoiceData.send_mail_settings.enabled"
              md="12"
              lg="12"
            >
              <validation-provider
                #default="{errors}"
                :name="$t('To')"
                rules="required"
              >
                <!-- Name -->
                <b-col cols="12">

                  <b-form-group
                    label="To"
                    label-cols-md="2"
                    label-for="mailto"
                  >
                    <div class="d-flex align-items-center justify-content-center column-is-mobile">

                      <b-form-tags
                        v-model="invoiceSendData.receivers"
                        :add-on-change="true"
                        size="lg"
                        input-id="mailto"
                        :input-attrs="{ 'aria-describedby': 'tags-validation-help' }"

                        placeholder="Enter a receiver mail and press enter..."
                        :tag-validator="emailListValidation"
                        separator=" "
                        :state="errors.length > 0 ? false:null"
                      />
                      <b-button
                        v-if="!showCc"
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        class="ml-1"
                        variant="outline-primary"
                        size="sm"
                        @click="showCc=true"
                      >
                        Cc
                      </b-button>
                      <b-button
                        v-if="!showBcc"
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        class="ml-1"
                        variant="outline-primary"
                        size="sm"
                        @click="showBcc=true"
                      >
                        Bcc
                      </b-button>
                    </div>
                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                </b-col>
              </validation-provider>
              <validation-provider
                v-if="recurringInvoiceData.send_mail_settings.enabled"
                #default="{errors}"
                :name="$t('Cc')"
              >
                <!-- Name -->
                <b-col cols="12">

                  <b-form-group
                    label="Cc"
                    label-cols-md="2"
                    label-for="mail-cc"
                  >
                    <b-form-tags
                      v-model="recurringInvoiceData.send_mail_settings.cc"
                      size="lg"
                      input-id="mail-cc"
                      :input-attrs="{ 'aria-describedby': 'tags-validation-help' }"
                      :add-on-change="true"
                      :placeholder="$t('Enter a cc mail...')"
                      :tag-validator="emailListValidation"
                      separator=" "
                      :state="errors.length > 0 ? false:null"
                    />

                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                </b-col>
              </validation-provider>
              <validation-provider

                v-if="recurringInvoiceData.send_mail_settings.enabled"
                #default="{errors}"
                :name="$t('Bcc')"
              >
                <!-- Name -->
                <b-col cols="12">

                  <b-form-group
                    label="Bcc"
                    label-cols-md="2"
                    label-for="mail-bcc"
                  >
                    <b-form-tags
                      v-model="recurringInvoiceData.send_mail_settings.bcc"
                      size="lg"
                      input-id="mail-bcc"
                      :input-attrs="{ 'aria-describedby': 'tags-validation-help' }"
                      :add-on-change="true"
                      placeholder="Enter a bcc mail..."
                      :tag-validator="emailListValidation"
                      separator=" "
                      :state="errors.length > 0 ? false:null"
                    />

                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                </b-col>
              </validation-provider>
              <validation-provider
                v-if="recurringInvoiceData.send_mail_settings.enabled"
                #default="{errors}"
                :name="$t('Subject')"
                rules="required"
              >
                <!-- Name -->
                <b-col cols="12">

                  <b-form-group
                    label="Subject"
                    label-cols-md="2"
                    label-for="subject"
                  >

                    <b-form-input
                      id="subject"
                      v-model="recurringInvoiceData.send_mail_settings.subject"
                      size="lg"
                      placeholder="Enter a mail subject..."
                      :state="errors.length > 0 ? false:null"
                    />

                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                </b-col>
              </validation-provider>
              <validation-provider
                v-if="recurringInvoiceData.send_mail_settings.enabled"
                #default="{errors}"
                :name="$t('Content')"
                rules="required"
              >
                <!-- Name -->
                <b-col cols="12">

                  <b-form-group
                    label="Content"
                    label-cols-md="2"
                    label-for="content"
                  >

                    <b-form-textarea
                      id="subject"
                      v-model="recurringInvoiceData.send_mail_settings.content"
                      size="lg"
                      rows="4"
                      placeholder="Enter a mail subject..."
                      :state="errors.length > 0 ? false:null"
                    />

                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                </b-col>
              </validation-provider>

            </b-col>
            <!--/ Name -->
            <div class="d-flex justify-content-between align-items-center mt-2">
              <div />
              <b-button

                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="primary"
                size="lg"
                @click="saveData"
              >
                Save Recurring Settings
              </b-button>
            </div>
          </div>
        </b-form>
        </validation-observer>

      </b-col>

    </b-row>

  </div>

</template>

<script>
import {
  BForm, BFormGroup, BRow, BCol, BFormInvalidFeedback, BButton, BFormInput, BFormTextarea, BFormTags, BFormCheckbox, BFormDatepicker,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import vSelect from 'vue-select'

export default {
  components: {
    BForm,
    BFormGroup,
    BRow,
    BFormTextarea,
    BCol,
    BFormTags,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BButton,
    BFormDatepicker,
    BFormInput,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  props: {
    invoiceData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showCc: false,
      showBcc: false,
      required,
      alphaNum,
      email,
      isSuccess: false,
      successMsg: 'Discount updated',
      errorData: null,
      recurringPeriodOptions: [
        { value: 1, label: 'Monthly' },
        { value: 2, label: 'BiWeekly' },
        { value: 3, label: 'Weekly' },
        { value: 4, label: 'Daily' },
      ],
      blankRecurringSettingData: {
        enable_recurring: false,
        recurring_period: 1,
        start_date: null,
        end_date: null,
        send_mail_settings: {
          enabled: false,
          subject: '',
          content: '',
          enable_send_mail: false,
          receivers: [],
          attachments: [],
          cc: [],
        },
      },
      recurringInvoiceData: null,
    }
  },
  watch: {
    'invoiceData.content.billing': {
      deep: true,
      handler() {
        this.resetForm()
      },
    },
  },
  created() {
    if (this.invoiceData.recurring_settings) {
      this.recurringInvoiceData = this.invoiceData.recurring_settings
    } else {
      this.resetForm()
    }
  },
  methods: {
    // eslint-disable-next-line no-shadow
    emailListValidation(email) {
      const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      if (email.match(mailformat)) {
        return true
      }

      return false
    },

    saveData() {
      this.$refs.refFormRecurringData.validate().then(success => {
        if (success) {
          this.$emit('handleSend', this.recurringInvoiceData)
        }
      })
    },
    resetForm() {
      this.recurringInvoiceData = JSON.parse(JSON.stringify(this.blankRecurringSettingData))
      if (this.invoiceData.content.billing) {
        this.recurringInvoiceData.send_mail_settings.receivers = [this.invoiceData.content.billing.email]
      }
      this.recurringInvoiceData.send_mail_settings.subject = `Invoice #${this.invoiceData.content.invoice_number} from ${this.invoiceData.content.company_info.name}`
      const today = this.$moment()
      this.recurringInvoiceData.start_date = today.format('YYYY-MM-DD')
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
