<template>
  <div>
    <div class="d-flex justify-content-end align-items-center mt-1 mr-1 mb-1">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        size="lg"
        variant="warning"
        :block="isMobile"
        @click="$emit('close')"
      >
        Close Preview
      </b-button>
    </div>
    <invoice-preview
      :invoice-data="invoiceData"
    />
  </div>
</template>

<script>
import {
BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import invoicePreview from '@core/components/invoice-themes/Template.vue'
import { isMobile } from 'mobile-device-detect'

export default {
  components: {
   invoicePreview,
   BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    invoiceData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
isMobile,
    }
  },
  watch: {
  },
  created() {

  },
  methods: {
  },
  setup() {

  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
